const API_BASE_URL = 'https://xeroapi.graymedialtd.com';  // Update this to your worker URL

interface XeroContact {
  ContactID: string;
  Name: string;
  EmailAddress: string;
  CompanyName?: string;
  FirstName?: string;
  LastName?: string;
}

export const xeroApi = {
  async getContacts(page: number = 1, pageSize: number = 10): Promise<{
    contacts: XeroContact[];
    totalCount: number;
  }> {
    const token = localStorage.getItem('xero_token');
    if (!token) {
      throw new Error('No Xero token found');
    }

    console.log('Fetching contacts with token:', token.substring(0, 10) + '...'); // Debug log

    try {
      const response = await fetch(
        `${API_BASE_URL}/contacts`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Xero-Tenant-Id': localStorage.getItem('xero_tenant_id') || '',
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Xero API Error:', {
          status: response.status,
          statusText: response.statusText,
          error: errorText
        });
        throw new Error(`Failed to fetch contacts: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Xero API Response:', data); // Debug log
      
      // Handle pagination in memory for now
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const paginatedContacts = (data.Contacts || []).slice(startIndex, endIndex);
      
      return {
        contacts: paginatedContacts,
        totalCount: data.Contacts?.length || 0,
      };
    } catch (error) {
      console.error('Error fetching contacts:', error);
      throw error;
    }
  },

  async getTenants(): Promise<{ tenantId: string, tenantName: string }[]> {
    const token = localStorage.getItem('xero_token');
    if (!token) {
      throw new Error('No Xero token found');
    }

    const response = await fetch(
      `${API_BASE_URL}/tenants`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch tenants');
    }

    const data = await response.json();
    return data.Tenants.map((tenant: any) => ({
      tenantId: tenant.TenantId,
      tenantName: tenant.TenantName,
    }));
  },
};
