import { useState, useEffect } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import { ClientsTable } from './ClientsTable';
import { xeroApi } from '../../services/xero/xeroApi';
import { xeroAuth } from '../../services/xero/xeroAuth';
import { StyledHeading } from '../common/StyledHeading';

const Clients = () => {
  const [contacts, setContacts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        if (!xeroAuth.isAuthenticated()) {
          setLoading(false);
          return;
        }

        setLoading(true);
        const data = await xeroApi.getContacts(page + 1, rowsPerPage);
        setContacts(data.contacts);
        setTotalCount(data.totalCount);
      } catch (error) {
        console.error('Failed to fetch contacts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, [page, rowsPerPage]);

  if (!xeroAuth.isAuthenticated()) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <StyledHeading>Clients</StyledHeading>
        <Box sx={{ mt: 4 }}>
          <Button 
            variant="contained" 
            onClick={() => xeroAuth.initiateLogin()}
            sx={{ mt: 2 }}
          >
            Connect to Xero
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <StyledHeading>Clients</StyledHeading>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <ClientsTable
          contacts={contacts}
          totalCount={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          loading={loading}
        />
      )}
    </Box>
  );
};

export default Clients;
