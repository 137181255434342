import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { xeroAuth } from '../../services/xero/xeroAuth';

const XeroCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        
        if (!code || !state) {
          throw new Error('Missing code or state');
        }

        await xeroAuth.handleCallback(code, state);
        navigate('/clients');
      } catch (error) {
        console.error('Xero callback error:', error);
        navigate('/');
      }
    };

    handleCallback();
  }, [searchParams, navigate]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
};

export default XeroCallback;
