const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const REDIRECT_URI = import.meta.env.VITE_GOOGLE_REDIRECT_URI;
const ALLOWED_DOMAIN = 'graymedialtd.com';

export const googleAuth = {
  isAuthenticated: false,

  async signIn() {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      scope: 'email profile',
      callback: (response) => {
        if (response.access_token) {
          this.handleAuthResponse(response.access_token);
        }
      },
    });
    client.requestAccessToken();
  },

  async handleAuthResponse(accessToken: string) {
    try {
      const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      
      // Verify email domain
      if (data.email?.endsWith(`@${ALLOWED_DOMAIN}`)) {
        this.isAuthenticated = true;
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('accessToken', accessToken);
        window.location.href = '/';
      } else {
        throw new Error('Unauthorized domain');
      }
    } catch (error) {
      console.error('Authentication error:', error);
      this.signOut();
    }
  },

  signOut() {
    this.isAuthenticated = false;
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    window.location.href = '/login';
  },

  checkAuth() {
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('user');
    return !!(token && user);
  }
};
