const XERO_CLIENT_ID = import.meta.env.VITE_XERO_CLIENT_ID;
const XERO_REDIRECT_URI = import.meta.env.VITE_XERO_REDIRECT_URI;

interface XeroTokenResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export const xeroAuth = {
  async initiateLogin() {
    const state = Math.random().toString(36).substring(7);
    localStorage.setItem('xero_state', state);

    const params = new URLSearchParams({
      response_type: 'code',
      client_id: XERO_CLIENT_ID,
      redirect_uri: XERO_REDIRECT_URI,
      scope: 'offline_access accounting.contacts.read',
      state: state
    });

    window.location.href = `https://login.xero.com/identity/connect/authorize?${params.toString()}`;
  },

  async handleCallback(code: string, state: string) {
    const savedState = localStorage.getItem('xero_state');
    if (state !== savedState) {
      throw new Error('State mismatch');
    }

    const tokenResponse = await this.getToken(code);
    this.saveToken(tokenResponse);
    return tokenResponse;
  },

  async getToken(code: string): Promise<XeroTokenResponse> {
    const response = await fetch('https://identity.xero.com/connect/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: XERO_REDIRECT_URI,
        client_id: XERO_CLIENT_ID,
        client_secret: import.meta.env.VITE_XERO_CLIENT_SECRET,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to get token');
    }

    return response.json();
  },

  async refreshToken(refreshToken: string): Promise<XeroTokenResponse> {
    const response = await fetch('https://xeroapi.graymedialtd.com/connect/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: XERO_CLIENT_ID,
        client_secret: import.meta.env.VITE_XERO_CLIENT_SECRET,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    return response.json();
  },

  saveToken(tokenResponse: XeroTokenResponse) {
    localStorage.setItem('xero_token', tokenResponse.access_token);
    localStorage.setItem('xero_refresh_token', tokenResponse.refresh_token);
    localStorage.setItem('xero_token_expires', 
      (Date.now() + tokenResponse.expires_in * 1000).toString()
    );
  },

  isAuthenticated() {
    const token = localStorage.getItem('xero_token');
    const expiresAt = localStorage.getItem('xero_token_expires');
    return token && expiresAt && Date.now() < parseInt(expiresAt);
  },

  async ensureValidToken() {
    if (!this.isAuthenticated()) {
      const refreshToken = localStorage.getItem('xero_refresh_token');
      if (refreshToken) {
        const tokenResponse = await this.refreshToken(refreshToken);
        this.saveToken(tokenResponse);
      } else {
        this.initiateLogin();
      }
    }
  }
};
